
function App() {
  return (
    <div className='container'>
      <div className="p-5">
        <p> Say 'hi' if you got my contact info. </p>
      </div>
    </div>
  );
}

export default App;
